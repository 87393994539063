import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';

import { combineLatest, map } from 'rxjs';

import { MessageItemPost, MessageThreadPost } from 'src/app/modules/core/models/message.model';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';
import * as MessageActions from 'src/app/modules/ngrx-store/message/message.actions';
import * as MessageSelectors from 'src/app/modules/ngrx-store/message/message.selectors';
import * as fromRoot from 'src/app/modules/ngrx-store/reducers';

import { CORE_ROUTES } from 'src/app/modules/shared/constants/route-paths';
import { UserInfo } from 'src/app/modules/shared/types/auth';

@Injectable()
export class MessageFacade {
	messageThreads$ = this.store.pipe(select(MessageSelectors.getMessageThreads));
	messageThreadsFailure$ = this.store.pipe(select(MessageSelectors.getMessageThreadsFailure));
	messageThreadsPending$ = this.store.pipe(select(MessageSelectors.getMessageThreadsPending));
	messageThreadsSuccess$ = this.store.pipe(select(MessageSelectors.getMessageThreadsSuccess));
	postMessageThreadPending$ = this.store.pipe(select(MessageSelectors.getPostMessageThreadPending));
	postMessageThreadFailure$ = this.store.pipe(select(MessageSelectors.getPostMessageThreadFailure));
	postMessageThreadSuccess$ = this.store.pipe(select(MessageSelectors.getPostMessageThreadSuccess));

	constructor(protected store: Store<fromRoot.AppDomainPartialState>, private appFacade: AppFacade) { }

	getUnreadMessageThreads() {
		return combineLatest([this.appFacade.userInfo$, this.messageThreads$]).pipe(
			map(([userInfo, messageThreads]) => 
				messageThreads?.filter(thread => 
					!window.location.pathname.includes(CORE_ROUTES.Preview)
					&& (thread.participants?.some(participant => participant.identityKey === (userInfo as UserInfo).accountId && participant.unreadCount > 0)
						|| !thread.participants?.some(participant => participant.identityKey === (userInfo as UserInfo).accountId))
				)
			)
		);
	}

	loadMessageThreads() {
		this.store.dispatch(MessageActions.loadMessageThreads());
	}

	loadMessageItems(messageThreadId: number) {
		this.store.dispatch(MessageActions.loadMessageItems({ messageThreadId }));
	}
	
	postMessageThread(messageThread: MessageThreadPost): void {
		this.store.dispatch(MessageActions.postMessageThread({ messageThread }));
	}

	postMessageItem(messageItem: MessageItemPost): void {
		this.store.dispatch(MessageActions.postMessageItem({ messageItem }));
	}

	markAsRead(messageThreadId: number): void {
		this.store.dispatch(MessageActions.markAsRead({ messageThreadId }));
	}

	resetMessageThreads() {
		this.store.dispatch(MessageActions.resetMessageThreads());
	}
}