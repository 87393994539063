import { createSelector } from '@ngrx/store';

import { State } from 'src/app/modules/ngrx-store/message/message.reducer';
import { getAppDomainState } from 'src/app/modules/ngrx-store/selectors';

/**
 * loadMessageThreads
 */
export const getMessageThreads = createSelector(
	getAppDomainState,
	(state: State) => state.messageThreads
);

export const getMessageThreadsFailure = createSelector(
	getAppDomainState,
	(state: State) => state.loadMessageThreadsFailure
);

export const getMessageThreadsPending = createSelector(
	getAppDomainState,
	(state: State) => state.loadMessageThreadsPending
);

export const getMessageThreadsSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.loadMessageThreadsSuccess
);

/**
 * loadMessageItems
 */
export const getMessageItemsFailure = createSelector(
	getAppDomainState,
	(state: State) => state.loadMessageItemsFailure
);

export const getMessageItemsPending = createSelector(
	getAppDomainState,
	(state: State) => state.loadMessageItemsPending
);

export const getMessageItemsSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.loadMessageItemsSuccess
);

/**
 * postMessageThread
 */
export const getPostMessageThreadFailure = createSelector(
	getAppDomainState,
	(state: State) => state.postMessageThreadFailure
);

export const getPostMessageThreadPending = createSelector(
	getAppDomainState,
	(state: State) => state.postMessageThreadPending
);

export const getPostMessageThreadSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.postMessageThreadSuccess
);

/**
 * postMessageItem
 */
export const getPostMessageItemFailure = createSelector(
	getAppDomainState,
	(state: State) => state.postMessageItemFailure
);

export const getPostMessageItemPending = createSelector(
	getAppDomainState,
	(state: State) => state.postMessageItemPending
);

export const getPostMessageItemSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.postMessageItemSuccess
);

/**
 * markAsRead
 */
export const getMarkAsReadFailure = createSelector(
	getAppDomainState,
	(state: State) => state.markAsReadFailure
);

export const getMarkAsReadPending = createSelector(
	getAppDomainState,
	(state: State) => state.markAsReadPending
);

export const getMarkAsReadSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.markAsReadSuccess
);